/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* Importing Bootstrap SCSS file. */

@import '~bootstrap/scss/bootstrap';
@import 'theme.scss';
html,
body {
    height: 100%;
    width: 100%;
    font-family: "Poppins", sans-serif !important;
}

.navbar-brand {
    img {
        width: 188px;
    }
}

body {}

p {
    margin: 0;
}

.page-start {
    color: white;
}

.text-red {
    color: red;
}

.contact-button {
    width: 280px !important;
    height: 60px !important;
    // padding: 10px 50px;
    color: white !important;
    margin-top: 5px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
    font-size: 24px !important;
}

.contact-button-b-green {
    background-color: rgb(63, 195, 80) !important;
    position: relative;
}

.contact-button-b-green:before {
    background-image: url(assets/images/whatsapp.svg);
    height: 30px;
    width: 30px;
    content: " ";
    position: absolute;
    left: 18px;
    background-size: cover;
    top: 14px;
}

.contact-button-b-red {
    background-color: rgb(255, 61, 131) !important;
}

.page-start::before {
    content: " ";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 825px;
    background-size: 100% 100%;
    background: linear-gradient(90deg, #5e49d7 0%, #874cde 100%);
    // clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%);
}

.page-start-video-container {
    height: 432px;
    width: 100%;
    max-width: 768px;
    background-color: white;
    margin-top: 60px;
    border-radius: 22px;
    z-index: 2;
    box-shadow: 0px 0px 24px 0px rgba(black, 0.13);
    overflow: hidden;
}

.trusted-clients {
    margin-top: 100px;
    h2 {
        margin-bottom: 0px;
    }
    app-trusted-client-logo {
        height: 180px;
        width: 180px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.main-title {
    padding: 40px 20px;
    .headings {
        max-width: 900px;
        margin: auto;
        margin-bottom: 30px;
        h1 {
            font-size: 43px;
            font-weight: 600;
            text-align: center;
        }
        h2 {
            font-size: 30px;
            font-weight: 500;
            text-align: center;
            padding: 20px 0;
        }
        p {
            padding: 0 10%;
            margin: 10px 0 0;
            text-align: center;
            font-size: 20px;
        }
    }
    .contact-button-b-green {
        position: relative;
        line-height: 47px;
        img {
            position: absolute;
            height: 34px;
            left: 15px;
            top: 11px;
        }
    }
}

.achievements,
.trusted-clients,
.content-creators,
.campaigns,.testimonials-container {
    max-width: 1200px !important;
    margin: 50px auto;
    text-align: center;
    h2 {
        margin-bottom: 10px;
    }
}

.article {
    img {
        width: 100%;
        max-width: 500px;
    }
}

@media screen and (max-width: $media-mobile) {
    .navbar {
        position: static !important;
    }
    .hide-mobile {
        display: none;
    }
    .page-start::before {
        height: 660px;
        background-position: center;
    }
    .trusted-clients {
        app-trusted-client-logo {
            height: 150px;
            width: 150px;
        }
    }
    .main-title {
        padding: 30px 20px;
        .headings {
            h1 {
                font-size: 33px !important;
                font-weight: 600;
                text-align: center;
            }
            p {
                padding: 0;
                margin: 10px 0 0;
                text-align: center;
                font-size: 18px !important;
            }
        }
        .contact-button-b-green {
            position: relative;
            line-height: 47px;
            img {
                position: absolute;
                height: 34px;
                left: 15px;
                top: 11px;
            }
        }
    }
    .page-start-video-container {
        height: 350px !important;
        width: 100%;
        max-width: 768px;
        background-color: white;
        margin-top: 80px;
        border-radius: 22px;
        z-index: 2;
        box-shadow: 0px 0px 24px 0px rgba(black, 0.13);
        overflow: hidden;
        iframe {
            width: 100%;
            height: 100%;
        }
    }
    .achievements,
    .trusted-clients,
    .content-creators,
    .campaigns {
        h2 {
            font-size: 26px;
            font-weight: 700;
            padding:0 15px;
        }
    }
}
@media screen and (max-width: 500px) {
    .page-start-video-container {
        height: 221px !important;
    }
}

.modal-content {
    border-radius: 10px !important;
}
.awards-div {
    margin-top: 20px;
    .ads {
        background-color: #141414;
        background-image: url(/assets/images/ad-background.jpg);
        height: 500px;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 15px;
        width: 80vw;
        display: block;
        background-position: center;
        padding: 50px;
        .ad-content {
            background-image: url(/assets/images/ad.png);
            height: 100%;
            width: auto;
            background-repeat: no-repeat;
            background-size: contain;
            background-position:center;
        }
    }
}
@media screen and (max-width: $media-mobile) {
    .awards-div {
        .ads {
            height: 400px;
        }
    }
}
@media screen and (max-width: 500px) {
    .awards-div {
        .ads {
            height: 300px;
            padding: 30px;
        }
    }
}
@media screen and (max-width: 350px) {
    .awards-div {
        .ads {
            height: 250px;
            padding: 25px;
        }
    }
}
@media screen and (max-width: 1199px) and (min-width: 992px) {
    .nav-link {
        font-size: 14px !important;
    }
}
.m-b-10 {
    margin-bottom: 10px !important;
}